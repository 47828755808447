// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$dark-text: #262626;
$light-text: #ffffff;

// WatchGuard Palettes
$wg-blue-palette: mat.define-palette(
  (
    50: #edf9fc, // ice
    100: #b6e8f2,
    200: #7ed2e2, // sky
    300: #62c8df,
    400: #57c5d9, // lagoon
    500: #48b2cd,
    600: #43a3ba,
    700: #359db0, // robin-egg
    800: #367b88, // pacific
    900: #2c585d,
    contrast: (
      50: $dark-text,
      100: $dark-text,
      200: $dark-text,
      300: $dark-text,
      400: $light-text,
      500: $light-text,
      600: $light-text,
      700: $light-text,
      800: $light-text,
      900: $light-text,
    ),
  ),
  800
);

$wg-red-palette-obj: (
  50: #fff0f0, //blush
  100: #ffc7b8,
  200: #ffa28a,
  300: #ff795b,
  400: #ff5436,
  500: #ff200c,
  600: #ff1507,
  700: #ff0000, // watchguard-red
  800: #f10000,
  900: #cf0000, // ruby
  contrast:
    (
      50: $dark-text,
      100: $dark-text,
      200: $dark-text,
      300: $dark-text,
      400: $light-text,
      500: $light-text,
      600: $light-text,
      700: $light-text,
      800: $light-text,
      900: $light-text,
    ),
);

$wg-watchguard-red-palette: mat.define-palette($wg-red-palette-obj, 700);
$wg-ruby-palette: mat.define-palette($wg-red-palette-obj, 900);

$wg-theme: mat.define-light-theme(
  (
    color: (
      primary: $wg-blue-palette,
      accent: $wg-ruby-palette,
      warn: $wg-watchguard-red-palette,
    ),
    typography: mat.define-typography-config(),
  )
);

@include mat.all-component-themes($wg-theme);

// Global Styles

*{
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
}

a {
  text-decoration: none;

  &,
  &:link,
  &:visited,
  &:focus,
  &:hover,
  &:active {
    color: mat.get-color-from-palette($wg-blue-palette, 700);
  }

  &.mat-tab-link {
    &,
    &:link,
    &:visited,
    &:focus,
    &:hover,
    &:active {
      color: mat.get-color-from-palette($wg-blue-palette, '300-contrast');
    }
  }
}

.mat-mdc-card-title {
  font-size: 16px !important;
}

.card-header-wg {
  background-color: #ae0000;
  color: #ffffff;
  padding: 10px 16px !important;
  display: flex;
  justify-content: space-between;
}

.grayscaleblur {
  filter: blur(3px);
}

.hidden-property {
  display: none !important;
}

.admin-view-dialog {
  .mat-mdc-dialog-surface {
    padding: 20px !important;
  }
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-line-height: 30px;
  --mdc-snackbar-supporting-text-weight: 500;
  --mdc-snackbar-supporting-text-color: black;
  --mdc-snackbar-container-color: transparent;
  --mat-snack-bar-button-color: #ca5951;
  border-radius: 4px;
}

.mat-mdc-snack-bar-container .mdc-button {
  font-size: 14px;
  font-weight: 700;
  padding-left: 60%;
}
 
.green-snackbar {
  background: #b6e0b6;
  border: #525050 1px solid;
}

.green-snackbar button {
  color: green !important;
}

.red-snackbar {
  background: #f2dede;
  border: #525050 1px solid;
}
